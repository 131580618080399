<template>
  <dl class="flex flex-wrap gap-3 mb-3 md:w-full">
    <div class="flex justify-between flex-auto px-4 py-2 overflow-hidden rounded-lg shadow-md overflow state_unknown">
      <dt class="my-auto text-sm truncate">{{ $t("device.header.serialNumber") }}</dt>
      <dd class="my-auto font-semibold text-md" v-if="device">{{ device.serialNumber }} </dd>
      <dd class="my-auto font-semibold text-md" v-else>-</dd>
    </div>

    <div class="flex justify-between flex-auto px-4 py-2 overflow-hidden rounded-lg shadow-md overflow state_unknown">
      <dt class="my-auto text-sm truncate">{{ $t("device.header.id") }}</dt>
      <dd class="my-auto font-semibold text-md">{{ getDeviceId(device) ? getDeviceId(device) : "-" }} </dd>
    </div>

    <div class="flex justify-between flex-auto px-4 py-2 overflow-hidden rounded-lg shadow-md overflow state_unknown">
      <dt class="my-auto text-sm truncate">{{ $t("device.header.firmware") }} </dt>
      <dd class="my-auto font-semibold text-md" v-if="device.firmware && device.firmware.version">{{ device.firmware.version }} </dd>
      <dd class="my-auto font-semibold text-md" v-else>-</dd>
    </div>
  </dl>

  <div class="relative md:h-16">
    <dl class="relative top-0 flex flex-wrap justify-between w-full gap-3 md:absolute ">

      <!-- Network status -->
      <div v-if="hasNetworkAlert" ref="networkTooltipTarget" class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
        <p>Erklärungstext Alarms </p>
        <p>Network</p>
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>

      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 md:flex-1 flex-auto justify-between md:w-full relative h-full',
        ...onlineStatusColor(device, 'network'),
        hasNetworkAlert ? 'cursor-pointer' : ''
      ]">

        <div v-if="hasNetworkAlert" data-accordion="open">
          <!-- network -->
          <div class="flex flex-wrap justify-between text-gray-800 bg-transparent" id="accordion-open-heading-network" data-accordion-target="#accordion-open-body-network" aria-expanded="false" aria-controls="accordion-open-body-network">
            <div class="flex justify-between w-full">
              <div class="justify-between block">
                <dt class="text-sm truncate">{{ $t("device.header.connectivityStatus") }}</dt>
                <div class="font-semibold text-md">
                  {{ getMostCriticalAlert("network", device) }}
                </div>
              </div>
              <div class="flex items-center flex-shrink-0 " ref="networkTooltipTrigger">
                <ExclamationCircleIcon class="w-8 h-8 px-1 mx-1 rounded-full" aria-hidden="true" />
              </div>


            </div>
            <div class="flex justify-center flex-1 w-full mt-2 text-gray-400">
              <svg data-accordion-icon class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </div>
          </div>
          <div id="accordion-open-body-network" class="hidden" aria-labelledby="accordion-open-heading-network">
            <div class="pb-0" v-html="getAllAlerts('network', device)">
            </div>
          </div>

        </div>
        <!-- no alarm -->
        <div v-else>
          <div class="flex justify-between w-full">
            <div class="justify-between block">
              <dt class="text-sm truncate">{{ $t("device.header.connectivityStatus") }}</dt>
              <div class="font-semibold text-md">
                <span v-if="device.isOnline">
                  Online via 4G/LTE {{ getConnectionStatus(device) }}
                </span>
                <span v-else>{{ presenceStatusLabel(device) }}</span>
              </div>
            </div>
            <div class="flex items-center flex-shrink-0 ">
              <DeviceMobileIcon class="w-8 h-8 px-1 mx-1 rounded-full" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>

      <!-- IS WIFI CONNECTED -->
      <div v-if="isConnectedWifi && hasWifiAlert" ref="wifiTooltipTarget" class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
        <p>Erklärungstext Alarms </p>
        <p>WIFI</p>
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>

      <div v-show="isConnectedWifi" :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 md:flex-1 flex-auto h-full',
        ...wifiStatusColor(device, 'power'),
        hasWifiAlert ? 'cursor-pointer' : '']">

        <div v-if="hasWifiAlert" data-accordion="open">
          <!-- wifi -->
          <div class="flex flex-wrap justify-between text-gray-800 bg-transparent " id="accordion-open-heading-power" data-accordion-target="#accordion-open-body-wifi" aria-expanded="false" aria-controls="accordion-open-body-wifi">
            <div class="flex justify-between w-full">
              <div class="justify-between block">
                <dt class="flex text-sm truncate">{{ $t("stateSummary.label.wifiConnection") }}</dt>
                <div class="font-semibold text-md">
                  {{ getMostCriticalAlert("wifi", device) }}
                </div>
              </div>
              <div class="flex items-center flex-shrink-0 " ref="wifiTooltipTrigger">
                <ExclamationCircleIcon class="w-8 h-8 px-1 mx-1 rounded-full" aria-hidden="true" />
              </div>
            </div>
            <div class="flex justify-center flex-1 w-full mt-2 text-gray-400">
              <svg data-accordion-icon class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </div>
          </div>
          <div id="accordion-open-body-wifi" class="hidden" aria-labelledby="accordion-open-heading-wifi">
            <div class="pb-0" v-html="getAllAlerts('wifi', device)">
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-between w-full">
            <div class="justify-between block w-full sm:flex md:block">
              <dt class="flex text-sm truncate">{{ $t("stateSummary.label.wifiConnection") }}</dt>
              <dd class="font-semibold text-md"> {{ wifiStatusText(device, $t) }} </dd>
            </div>
            <div class="items-center flex-shrink-0 hidden md:flex">
              <WifiIcon class="w-8 h-8 px-1 mx-1 rounded-full" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>

      <!-- Power status -->
      <div v-if="hasPowerAlert" ref="powerTooltipTarget" class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
        <p>Erklärungstext Alarms </p>
        <p>hasPowerAlert</p>
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>

      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 md:flex-1 flex-auto h-full',
        ...powerSupplyStatusColor(device, 'power'),
        hasPowerAlert ? 'cursor-pointer' : '']">
        <div v-if="hasPowerAlert" data-accordion="open">
          <!-- power -->
          <div class="flex flex-wrap justify-between text-gray-800 bg-transparent " id="accordion-open-heading-power" data-accordion-target="#accordion-open-body-power" aria-expanded="false" aria-controls="accordion-open-body-power">
            <div class="flex justify-between w-full">
              <div class="justify-between block">
                <dt class="flex text-sm truncate">{{ $t("stateSummary.label.powerSupplyState") }}</dt>
                <div class="font-semibold text-md">
                  {{ getMostCriticalAlert("power", device) }}
                </div>
              </div>
              <div class="flex items-center flex-shrink-0 " ref="powerTooltipTrigger">
                <ExclamationCircleIcon class="w-8 h-8 px-1 mx-1 rounded-full" aria-hidden="true" />
              </div>
            </div>
            <div class="flex justify-center flex-1 w-full mt-2 text-gray-400">
              <svg data-accordion-icon class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </div>
          </div>
          <div id="accordion-open-body-power" class="hidden" aria-labelledby="accordion-open-heading-power">
            <div class="pb-0" v-html="getAllAlerts('power', device)">
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-between w-full">
            <div class="justify-between block w-full sm:flex md:block">
              <dt class="flex text-sm truncate">{{ $t("stateSummary.label.powerSupplyState") }}</dt>
              <dd class="font-semibold text-md"> {{ powerSupplyStatusText(device, $t) }} </dd>
            </div>
            <div class="items-center flex-shrink-0 hidden md:flex">
              <BatteryIcon :class="['h-8', 'w-8', 'rounded-full', 'px-1', 'mx-1']" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>

      <!-- Accessory status -->
      <div v-if="hasAccessoryAlert" ref="accessoryTooltipTarget" class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
        <p>Erklärungstext Alarms </p>
        <p>Accessory</p>
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>

      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 md:flex-1 flex-auto h-full',
        ...braceletPresenceStatusColor(device, 'accessories'),
        hasAccessoryAlert ? 'cursor-pointer' : ''
      ]
        ">
        <div v-if="hasAccessoryAlert" data-accordion="open">
          <!-- accessories -->
          <div class="flex flex-wrap justify-between text-gray-800 bg-transparent " id="accordion-open-heading-accessory" data-accordion-target="#accordion-open-body-accessory" aria-expanded="false" aria-controls="accordion-open-body-accessory">
            <div class="flex justify-between w-full">
              <div class="justify-between block">
                <dt class="flex text-sm truncate">{{ $t("stateSummary.label.braceletPresenceState") }}</dt>
                <div class="font-semibold text-md">
                  {{ getMostCriticalAlert("accessories", device) }}
                </div>
              </div>
              <div class="flex items-center flex-shrink-0 " ref="accessoryTooltipTrigger">
                <ExclamationCircleIcon class="w-8 h-8 px-1 mx-1 rounded-full" aria-hidden="true" />
              </div>
            </div>
            <div class="flex justify-center flex-1 w-full mt-2 text-gray-400">
              <svg data-accordion-icon class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </div>
          </div>
          <div id="accordion-open-body-accessory" class="hidden" aria-labelledby="accordion-open-heading-accessory">
            <div class="pb-0" v-html="getAllAlerts('accessories', device)">
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-between">
            <div class="justify-between block w-full sm:flex md:block">
              <dt class="flex text-sm truncate"> {{ $t("stateSummary.label.braceletPresenceState") }} </dt>
              <dd class="font-semibold text-md"> {{ braceletPresenceStatusText(device, $t) }} </dd>
            </div>
            <div class="items-center flex-shrink-0 hidden md:flex">
              <BraceletIcon :class="['h-8', 'w-8', 'rounded-full', 'px-1', 'mx-1']" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>


      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 md:flex-1 flex-auto h-full',
        ...dailyCheckInStatusColor(device),
      ]">
        <div class="flex justify-between ">
          <div class="justify-between block w-full sm:flex md:block">
            <dt class="text-sm truncate">{{ $t("device.general.dailyCheckIn") }}</dt>
            <dd class="font-semibold text-md"> {{ dailyCheckInStatusText(device, $t) }} </dd>
          </div>
          <div class="items-center flex-shrink-0 hidden md:flex">
            <CalendarIcon :class="['h-8', 'w-8', 'rounded-full', ...dailyCheckInStatusColor(device), 'px-1']" aria-hidden="true" />
          </div>
        </div>
      </div>
    </dl>

  </div>
</template>

<script>
import {
  powerSupplyStatusColor,
  braceletBatteryStatusColor,
  braceletPresenceStatusColor,
  onlineStatusColor,
  modemStatusColor,
  cloudPresenceStatusColor,
  devicePresenceStatusColor,
  dailyCheckInStatusColor,
  overallBraceletStatusColor,
  wifiStatusColor,
  batteryStatusColor,
  deviceIdentityStatusColor,

} from "@/lib/statusColors";
import { getAlertLevel, hasAlert, getMostCriticalAlert, getAllAlerts, hasWifiConnection } from "@/lib/alarms";
import { CalendarIcon, WifiIcon, DeviceMobileIcon, ExclamationCircleIcon } from "@heroicons/vue/outline";
import { BraceletIcon } from "@/components/icons";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { BatteryIcon } from "./icons";
import { useI18n } from "vue-i18n";
import { braceletPresenceStatusText, wifiStatusText, dailyCheckInStatusText, powerSupplyStatusText } from "@/lib/statusTexts";
import { Tooltip } from "flowbite";

export default {
  name: "DeviceStateSummary^",
  methods: {
    braceletPresenceStatusText,
    powerSupplyStatusText,
    onlineStatusColor,
    dailyCheckInStatusColor,
    modemStatusColor,
    overallBraceletStatusColor,
    wifiStatusColor,
    batteryStatusColor,
    deviceIdentityStatusColor,
    dailyCheckInStatusText,
    powerSupplyStatusColor,
    braceletBatteryStatusColor,
    braceletPresenceStatusColor,
    cloudPresenceStatusColor,
    devicePresenceStatusColor,
    getAlertLevel,
    hasAlert,
    getMostCriticalAlert,
    getAllAlerts,
    hasWifiConnection,
    wifiStatusText,

  },
  components: {
    CalendarIcon,
    BraceletIcon,
    BatteryIcon,
    WifiIcon,
    ExclamationCircleIcon,
    DeviceMobileIcon
  },

  setup() {
    const store = useStore();
    const { t } = useI18n();
    const device = computed(() => store.state.device.current);

    const networkTooltipTrigger = ref(null);
    const networkTooltipTarget = ref(null);

    const wifiTooltipTrigger = ref(null);
    const wifiTooltipTarget = ref(null);

    const accessoryTooltipTrigger = ref(null);
    const accessoryTooltipTarget = ref(null);

    const powerTooltipTrigger = ref(null);
    const powerTooltipTarget = ref(null);

    onMounted(() => {
      const options = {
        placement: 'bottom',
        triggerType: 'hover',
      }
      new Tooltip(networkTooltipTarget.value, networkTooltipTrigger.value, options);
      new Tooltip(wifiTooltipTarget.value, wifiTooltipTrigger.value, options);
      new Tooltip(accessoryTooltipTarget.value, accessoryTooltipTrigger.value, options);
      new Tooltip(powerTooltipTarget.value, powerTooltipTrigger.value, options);
    })


    const hasNetworkAlert = computed(() => hasAlert('network', device.value))
    const hasPowerAlert = computed(() => hasAlert('power', device.value))
    const hasAccessoryAlert = computed(() => hasAlert('accessories', device.value))

    const isConnectedWifi = computed(() => hasWifiConnection(device.value))
    const hasWifiAlert = computed(() => hasAlert("wifi", device.value))

    const presenceStatusLabel = (device) => {
      if (device.isOnline) {
        return t("device.errorStatus.ok");
      }
      if (device.stateSummary.deviceReportedDevicePresence === "offline_battery_empty") {
        return t("device.errorStatus.emptyBattery");
      }
      if (device.stateSummary.deviceReportedDevicePresence === "offline_low_power_mode") {
        return t("device.errorStatus.lowPowerMode");
      }
      if (device.stateSummary.deviceReportedDevicePresence === "offline_turned_off") {
        return t("device.errorStatus.off");
      }
      return t("device.errorStatus.missingDevice");
    };

    const getDeviceId = (device) => {
      return device.configV2.alarm_app.destinations.filter(destination => destination.scaip).map(destination => destination.scaip.controller_id).join(", ")
    };

    const getConnectionStatus = (device) => {
      let ret;
      if (device.telemetry && device.telemetry.modem) {
        ret = device.telemetry.modem.operator + "(" + device.telemetry.modem.technology + ")";
      }
      if (ret.length > 10) {
        ret = ret.substring(0, 10) + "...";
      }

      return ret;
    };

    const snakeToCamel = (str) =>
      str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));

    return {
      device,
      presenceStatusLabel,
      snakeToCamel,
      getDeviceId,
      getConnectionStatus,
      hasNetworkAlert,
      hasPowerAlert,
      hasAccessoryAlert,
      isConnectedWifi,
      hasWifiAlert,
      networkTooltipTrigger,
      networkTooltipTarget,
      wifiTooltipTrigger,
      wifiTooltipTarget,
      accessoryTooltipTrigger,
      accessoryTooltipTarget,
      powerTooltipTrigger,
      powerTooltipTarget
    };
  },
};
</script>
