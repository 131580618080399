const powerSupplyStatusText = (device, t) => {
  if (!device.isOnline) {
    return "-";
  }
  if (device.stateSummary.powerSupply === "connected") {
    return t("stateSummary.powerSupply.connected");
  }
  if (device.stateSummary.powerSupply === "disconnected") {
    return t("stateSummary.powerSupply.disconnected");
  }
  return "-";
};

const wifiStatusText = (device, t) => {
  // TODO hansi how will this be stored in the state summary?
  // TODO hansi rework whole wifi logic once backend is done

  if (device.stateSummary.powerSupply === "connected") {
    return "Wifi TODO" + t("stateSummary.powerSupply.connected");
  }
  if (device.stateSummary.powerSupply === "disconnected") {
    return "Wifi TODO" + t("stateSummary.powerSupply.disconnected");
  }
  return "-";
};

const braceletPresenceStatusText = (device, t) => {
  if (!device.isOnline) {
    return "-";
  }
  if (device.stateSummary.braceletBattery === "critical") {
    return t("stateSummary.braceletBattery.critical");
  }
  if (device.stateSummary.braceletPresence === "in_range") {
    return t("stateSummary.braceletPresence.inRange");
  }
  if (device.stateSummary.braceletPresence === "out_of_range") {
    return t("stateSummary.braceletPresence.outOfRange");
  }
  return "-";
};

const dailyCheckInStatusText = (device, t) => {
  if (!device.isOnline) {
    return "-";
  }
  if (device.stateSummary.dailyCheckIn === "active") {
    return t("stateSummary.dailyCheckIn.active");
  }
  if (device.stateSummary.dailyCheckIn === "paused_by_device") {
    return t("stateSummary.dailyCheckIn.pausedByDevice");
  }
  if (device.stateSummary.dailyCheckIn === "paused_by_platform") {
    return t("stateSummary.dailyCheckIn.pausedByPlatform");
  }
  if (device.stateSummary.dailyCheckIn === "missing") {
    return t("stateSummary.dailyCheckIn.missing");
  }
  return "-";
};

export { dailyCheckInStatusText, wifiStatusText, powerSupplyStatusText, braceletPresenceStatusText };
