import { API } from "aws-amplify";
import deviceQuery from "./graphql/device_query.graphql";
import { runQuery } from "@/api/query";
import devicesQuery from "@/api/graphql/devices_query.graphql";
import devicesIdsQuery from "@/api/graphql/devices_ids_query.graphql";
import deviceStartNetworkScanMutation from "@/api/graphql/device_start_network_scan.graphql";
import deviceUpdateOwnerMutation from "@/api/graphql/device_update_owner.graphql";
import deviceLatestNetworkScansQuery from "@/api/graphql/device_network_scans_query.graphql";
import aggregationQuery from "@/api/graphql/aggregation_query.graphql";
import deviceConfigUpdate from "@/api/graphql/device_config_update.graphql";
import configTemplateQuery from "@/api/graphql/config_template_query.graphql";
import configTemplateCreate from "@/api/graphql/config_template_create.graphql";
import configTemplateUpdate from "@/api/graphql/config_template_update.graphql";
import configTemplateDelete from "@/api/graphql/config_template_delete.graphql";

export const queryDevices = async (nextPageToken = null, filter = null) => {
  const result = await runQuery(devicesQuery, { nextPageToken, filter });

  let devices = result ? result.devices.edges.map((edge) => edge.node) : [];
  const devicesAggregation = result ? result.devices.aggregation : {};
  const hasNextPage = result ? result.devices.pageInfo.hasNextPage : false;
  const newNextPageToken = result ? result.devices.pageInfo.endCursor : "";

  devices = devices.map((device) => {
    if (device.configV2 && device.configV2.data && typeof device.configV2.data == "string") {
      return {
        ...device,
        configV2: JSON.parse(device.configV2.data),
      };
    }
    return device;
  });

  return {
    devices,
    hasNextPage,
    nextPageToken: newNextPageToken,
    aggregation: devicesAggregation,
  };
};

export const queryDevice = async (serialNumber) => {
  const result = await API.graphql({
    query: deviceQuery,
    variables: { serialNumber },
  });

  const device = result.data.devices.edges[0].node;
  device.configV2 = device.configV2.data ? JSON.parse(device.configV2.data) : {};

  return device;
};

export const queryDeviceIds = async () => {
  const result = await runQuery(devicesIdsQuery);
  return result ? result.devices.edges.map((edge) => edge.node) : [];
};

export const queryAggregations = async (filter = null) => {
  return await runQuery(aggregationQuery, { filter });
};

export const updateOwner = async (deviceIds, tenantId) => {
  return runQuery(deviceUpdateOwnerMutation, {
    deviceIds,
    tenantId,
  });
};

export const startNetworkScan = async (deviceId, advanced) => {
  return (
    await runQuery(deviceStartNetworkScanMutation, {
      deviceId,
      scanType: advanced ? "ADVANCED" : "BASIC",
    })
  ).device.startNetworkScan;
};

export const queryNetworkScans = async (deviceId) => {
  const result = await API.graphql({
    query: deviceLatestNetworkScansQuery,
    variables: { deviceId },
  });
  return result.data.devices.edges[0].node.networkScans.edges.map((edge) => edge.node);
};

export const updateConfigV2 = async (deviceId, config) => {
  return await runQuery(deviceConfigUpdate, {
    id: deviceId,
    data: JSON.stringify(config),
  });
};

export const createConfigTemplate = async (template) => {
  return await runQuery(configTemplateCreate, {
    name: template.name,
    ownerId: template.ownerId,
    applierIds: template.applierIds,
    template: template.template,
  });
};

export const updateConfigTemplate = async (template) => {
  return await runQuery(configTemplateUpdate, {
    templateId: template.id,
    name: template.name,
    applierIds: template.applierIds,
    template: template.template,
  });
};

export const deleteConfigTemplate = async (templateId) => {
  return await runQuery(configTemplateDelete, { templateId: templateId });
};

export const queryConfigTemplates = async (pageSize = 100) => {
  const result = await runQuery(configTemplateQuery, { pageSize: pageSize });
  const templates = result ? result.templates.edges.map((edge) => edge.node) : [];
  return templates;
};
